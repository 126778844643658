<template>
    <div>
        <div class="recruitment">
            <div>
                <div class="title">
                    <h2>人才招聘</h2>
                </div>
                <div class="main" style="margin:0 auto">
                  <el-table
                    class="table"
                    :data="tableData"
                    border
                    :row-class-name="tableRowClassName"
                    :span-method="spanMethod"
                    >
                    <el-table-column
                      prop="id"
                      label="序号"
                      width="50">
                    </el-table-column>
                    <el-table-column
                      prop="work"
                      label="招聘岗位"
                      width="180">
                    </el-table-column>
                    <el-table-column
                      prop="type"
                      label="专业要求">
                    </el-table-column>
                    <el-table-column
                      prop="edu"
                      label="学历">
                    </el-table-column>
                    <el-table-column
                      prop="count"
                      label="拟招聘人数">
                    </el-table-column>
                  </el-table>
                </div>
                <div class="main1 col8">
                  一、公司福利：<br/>1、公司提供五险（养老保险、医疗保险、失业保险、工伤保险、生育保险）；<br/>2、实行标准工时制度，5天7.5小时（上班时间：8:30—12：00， 14:00—18:00），双休，享有国家法定节假日；<br/>3、免费年度体检、工作餐补贴、员工活动、 公费培训等；<br/>4、公司不定期组织各类员工活动，丰富员工业余生活，如聚餐、团建、中秋博饼、年终尾牙等；<br/>5、给以新人入职培训、专业知识培训、日常学习等，让你在工作中不断成长，全方面提升工作素质及能力；<br/>6、为符合条件的应届生办理档案接收及落户；给予新引进人才生活补贴（本科1万，硕士5万，博士8万）<br/>二、地址：厦门市湖里区湖里大道33号西侧七楼（福建历思司法鉴定所）<br/>三、联系人：陈小姐 15980814232<br/>四、简历投递邮箱：1240018668@qq.com<br/>五、乘车路线：26路/302路/323路/109路/848路/655路华昌站下车 （联发集团保安亭边）

                </div>
            </div>
        </div>
        
    </div>
</template>

<script>
export default {
    data(){
        return{
          tableData:[
            {id:'1',work:'实验员/有机工程师',type:'化学、药学、法医、检验',edu:'本科及以上',count:'5人',},
            {id:'2',work:'鉴定助理',type:'环境工程、环境科学、生态学、生物化学、生物技术',edu:'本科及以上',count:'5人',},
            {row:2,col:1,id:'3',work:'销售助理',type:'不限',edu:'大专及以上',count:'3-5人',},
            {row:0,col:0,work:'销售工程师',type:'不限',edu:'大专及以上',count:'3-5人',},
            {row:3,col:1,id:'4',work:'硬件工程师',type:'电子、测控仪器、光学',edu:'本科及以上',count:'3-5人',},
            {row:0,col:0,work:'测试工程师',type:'计算机相关专业',edu:'大专及以上',count:'3-5人',},
            {row:0,col:0,work:'嵌入式工程师',type:'计算机相关专业',edu:'本科及以上',count:'3-5人',},
            {row:2,col:1,id:'5',work:'研发助理',type:'生物化学相关专业',edu:'硕士及以上',count:'10人',},
            {row:0,col:0,work:'研发工程师',type:'生物化学相关专业',edu:'博士及以上',count:'5人',},
          ],
            fileList:[],
            rules:{
                name:{required:true,message:'必填',trigger: 'blur'},
                phone:{required:true,message:'必填',trigger: 'blur'},
                email:{required:true,message:'必填',trigger: 'blur'},
            },
            dialogVisible:false,
            dialogFormVisible:false,
            form:{name:'',phone:'',email:'',intro:''},
            formLabelWidth: '120px',
            data:[
                {
                    label:'测试工程师',
                    year:'1-3年',
                    education:'本科',
                    count:'若干',
                    duty:'岗位职责： 1. 编写测试计划、测试用例；2. 搭建测试环境，进行功能测试、性能测试，反馈Bug List，并跟踪推动软件缺陷的修复；3. 必要时能够使用自动化工具完成一些重复性的测试，提高效率。4. 编写测试报告、用户手册等文档；',
                    content:'任职要求1. 大专及以上的学历，热爱测试工作；2. 一年以上软件测试经验、仪器测试经验；3. 具备优秀的学习和探索能力，具备良好的问题分析能力，有责任心、有耐心；4. 能够适应中长期出差；5. 有使用C#独立编写上位机软件经验者优先；'

                },
            ],
            details:{},
        }
    },
    methods:{
      tableRowClassName({rowIndex}) {
        if (rowIndex%2==0) {
          return 'warning-row';
        } else {
          return 'success-row';
        }
      }
    }
    

}
</script>
<style scoped>
.main1{
  line-height:2rem;
  text-align:left;
  margin:2rem auto;
}
>>>.table .el-table__cell{
  border-right: 1px solid #8e8e8e;
  border-bottom: 1px solid #828282!important;
}
.main{
  border:1px solid #8e8e8e;
}
>>>.table.el-table .warning-row {
    background: oldlace;
  }

>>>.table.el-table .success-row {
    background: #f0f9eb;
  }
</style>
